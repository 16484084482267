import { ClientContract } from 'ts/types/Contract'

import {
  ContractBillingTermsEnum,
  ContractInvoiceFrequencyEnum,
  PackageDepartmentEnum
} from '../../../__generated__/globalTypes'
import { Address, Contact } from '../../../ts/types/Contact'

import { SelectOptions } from 'luce-ui-components'

export type ContractFormData = {
  billingContactId: string
  billingAddressId: string
  purchaseOrder: string
  instructions: string
  billingTerms: ContractBillingTermsEnum
  email?: string
  phoneNumber: string
  invoiceFrequency: ContractInvoiceFrequencyEnum
  autoConfirmInvoice: boolean
  autoSendInvoiceEmail: string
}

export const invoiceFrequencyMapping: Record<
  ContractInvoiceFrequencyEnum,
  string
> = {
  [ContractInvoiceFrequencyEnum.PER_MONTH]: 'Per Month',
  [ContractInvoiceFrequencyEnum.PER_VISIT]: 'Per Visit'
}

export const invoiceFrequencyOptions = Object.entries(
  invoiceFrequencyMapping
).map(([value, label]) => ({
  value,
  label
}))

export const billingTermMapping: Record<ContractBillingTermsEnum, string> = {
  [ContractBillingTermsEnum.IMMEDIATE]: 'Immediate',
  [ContractBillingTermsEnum.NET_7]: 'Net 7',
  [ContractBillingTermsEnum.NET_14]: 'Net 14',
  [ContractBillingTermsEnum.NET_15]: 'Net 15',
  [ContractBillingTermsEnum.NET_30]: 'Net 30',
  [ContractBillingTermsEnum.NET_45]: 'Net 45',
  [ContractBillingTermsEnum.NET_60]: 'Net 60',
  [ContractBillingTermsEnum.NET_90]: 'Net 90'
}

export const billingTermOptions = Object.entries(billingTermMapping).map(
  ([value, label]) => ({
    value,
    label
  })
)

export const departmentMapping: Record<PackageDepartmentEnum, string> = {
  [PackageDepartmentEnum.HOME_CLEANING]: 'Home Cleaning',
  [PackageDepartmentEnum.AIRCON]: 'Aircon',
  [PackageDepartmentEnum.OFFICE_CLEANING]: 'Office Cleaning',
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: 'Carpet Upholstery',
  [PackageDepartmentEnum.HOME_BEAUTY]: 'Home Beauty',
  [PackageDepartmentEnum.CAR_WASH]: 'Car Wash',
  [PackageDepartmentEnum.BABYSITTER]: 'Baby Sitting',
  [PackageDepartmentEnum.HANDYMAN]: 'Handyman',
  [PackageDepartmentEnum.MASSAGE]: 'Massage'
}

export const departmentOptions = (
  customDepartmentMapping?: SelectOptions
): SelectOptions => {
  const mapping = customDepartmentMapping || departmentMapping
  const options = Object.entries(mapping)
                        .map(([value, label]) => ({
                          value,
                          label
                        }))
  options.unshift({ label: 'All Department', value: 'ALL' })
  return options
}

export const getInitialValues = (
  contract: ClientContract | null,
  primaryContact?: Contact,
  primaryAddress?: Address,
  billingTerms?: ContractBillingTermsEnum
): ContractFormData => {
  const [firstEmail] = primaryContact?.email ?? []
  const [firstPhone] = primaryContact?.phoneNumber ?? []

  if (contract) {
    const [firstEmail] = contract.contact.email
    const [firstPhone] = contract.contact.phoneNumber
    return {
      billingAddressId: contract.address.id,
      billingContactId: contract.contact.id,
      purchaseOrder: contract.poNumber ?? '',
      instructions: contract.instructions ?? '',
      email: firstEmail,
      phoneNumber: firstPhone,
      billingTerms: contract.billingTerms,
      invoiceFrequency: contract.invoiceFrequency,
      autoConfirmInvoice: contract.autoConfirmInvoice,
      autoSendInvoiceEmail: contract.autoSendInvoiceEmail ? 'YES' : 'NO'
    }
  }

  return {
    billingAddressId: primaryAddress?.id ?? '',
    billingContactId: primaryContact?.id ?? '',
    billingTerms: billingTerms ?? ContractBillingTermsEnum.NET_14,
    invoiceFrequency: ContractInvoiceFrequencyEnum.PER_MONTH,
    autoConfirmInvoice: true,
    autoSendInvoiceEmail: 'YES',
    purchaseOrder: '',
    instructions: '',
    email: firstEmail,
    phoneNumber: firstPhone,
    departmentOptions: departmentOptions
  }
}
